import React from 'react'

import cn from 'classnames'

import * as st from '~/assets/styl/WhereToBuy.module.styl'
import GoBackButton from './GoBackButton'

export default function WhereToBuy() {
  return (
    <div className={cn(st.whereToBuyContainer, 'page-width')}>
      <GoBackButton />
      <section className={st.whereToBuyWrapper}>
        <h1>Onde comprar</h1>
        <div>
          <iframe
            src="https://pointer.pertinhodemim.com"
            frameBorder="0"
          ></iframe>
        </div>
      </section>
    </div>
  )
}
