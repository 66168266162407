import React from 'react'
import { Head } from '~/components'
import Newsletter from '~/components/Newsletter'
import WhereToBuy from '~/components/WhereToBuy'

const Stores = () => (
  <>
    <Head />
    <WhereToBuy />
    <Newsletter />
  </>
)

export default Stores
